/** @format */
.PubKeyPicker {
  position: relative;

  &__activator {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.425em 0.1em;

    svg {
      display: block;
    }

    path {
      fill: #0037FF;
    }
  }
}
